import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import { AppBar, Avatar, Box, Button, IconButton, InputBase, Menu, MenuItem, Modal, TextField, Toolbar, Tooltip, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AdbIcon from '@mui/icons-material/Adb';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { useLocation, useNavigate } from 'react-router-dom';
import { routes } from '../Index';
import { url } from '../utils/constants';
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

async function renewToken() {
    try {
        const token = localStorage.getItem('token');
        const { data } = await axios.get(url + '/api/v1/renewToken', {
            headers: {
                Authorization: 'Bearer ' + token
            }
        });
        if (data && data.token) {

            localStorage.setItem('token', data.token);
        }
    } catch (error) {
        console.error('Erro ao renovar o token:', error);
    }
}

async function checkTokenExpiry() {
    const token = await localStorage.getItem('token');
    if (token) {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        const expiryTime = decodedToken.exp;
        const timeRemaining = expiryTime - currentTime;
        const tenMinutesInSec = 10 * 60;

        if (timeRemaining < tenMinutesInSec && timeRemaining > 0) {
            renewToken();
        } else if (timeRemaining < 0) {
            localStorage.removeItem('token');
        }
    }
}

export default function Header({ isLoggedIn, setLoggedIn }) {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [video, setVideo] = React.useState('');
    const [cover, setCover] = React.useState('');
    const [title, setTitle] = React.useState('');
    const navigate = useNavigate();
    const location = useLocation();

    const menuRoutes = routes.filter(route => route.showInMenu);

    React.useEffect(() => {
        switch (location.pathname) {
            case '/':
            case '/singup':
                return;
            default:
                const intervalId = setInterval(async () => {
                    await checkTokenExpiry();
                }, 60000);

                return () => clearInterval(intervalId);
        }
    }, []);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleClose = () => {
        setAnchorElNav(null);
        setAnchorElUser(null);
    };

    const handleChangeCompanyAdd = (e) => {
        e.preventDefault();
        navigate('/add-company');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('title', title);
        formData.append('video', video);
        formData.append('cover', cover);
        const token = localStorage.getItem('token');
        await axios.post(process.env.URL + '/api/v1/video', formData, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    };

    const handlePageClick = (page) => {
        navigate(`/${page.toLowerCase()}`);
    };

    const handleLogin = () => {
        navigate('/');
    }

    const handleMenuClick = (menuItem) => {
        switch (menuItem) {
            case 'Profile':
                handleProfileClick();
                break;
            case 'Account':
                handleAccountClick();
                break;
            case 'Dashboard':
                handleDashboardClick();
                break;
            case 'Logout':
                handleLogoutClick();
                break;
            default:
                break;
        }
        handleCloseUserMenu();
    };

    const handleProfileClick = () => {
        // Lógica para lidar com o clique no botão "Profile"
        console.log("Profile clicked");
    };

    const handleAccountClick = () => {
        // Lógica para lidar com o clique no botão "Account"
        console.log("Account clicked");
    };

    const handleDashboardClick = () => {
        // Lógica para lidar com o clique no botão "Dashboard"
        console.log("Dashboard clicked");
    };

    const handleLogoutClick = () => {
        localStorage.removeItem('token');
        navigate('/');
        console.log("Logout clicked");
    };

    const renderHeaderButtons = () => {
        switch (location.pathname) {
            case '/video':
                return (
                    <>
                        <Button color="inherit">Button A</Button>
                        <Button color="inherit">Button B</Button>
                    </>
                );
            case '/company':
                return <Button color="inherit">Button C</Button>;
            default:
                return (
                    <>
                        <Button color="inherit">Button D</Button>
                        <Button color="inherit">Button E</Button>
                    </>
                );
        }
    };

    const renderHeaderButtonLogin = () => {
        switch (location.pathname) {
            case '/':
                return <></>
                break;
            default:
                return <Button variant="contained" onClick={handleLogin}>LogIn</Button>
        }
    }


    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
                    <Typography variant="h6" sx={{ mr: 2, display: { xs: 'none', md: 'flex' }, fontFamily: 'monospace', fontWeight: 700, letterSpacing: '.3rem', color: 'inherit', textDecoration: 'none' }}>Streamly</Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton size="large" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleOpenNavMenu} color="inherit"><MenuIcon /></IconButton>
                        <Menu id="menu-appbar" anchorEl={anchorElNav} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} keepMounted transformOrigin={{ vertical: 'top', horizontal: 'right' }} open={Boolean(anchorElNav)} onClose={handleCloseNavMenu}>
                            {menuRoutes.map((page) => (
                                <MenuItem key={page.name} onClick={() => (page.path)}><Typography textAlign="center">{page.name}</Typography></MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
                    <Typography variant="h5" noWrap component="a" href="#app-bar-with-responsive-menu" sx={{ mr: 2, display: { xs: 'flex', md: 'none' }, flexGrow: 1, fontFamily: 'monospace', fontWeight: 700, letterSpacing: '.3rem', color: 'inherit', textDecoration: 'none' }}>LOGO</Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {menuRoutes.map((page) => (
                            <Button key={page.name} onClick={() => handlePageClick(page.path)} sx={{ my: 2, color: 'white', display: 'block' }}>{page.name}</Button>
                        ))}
                    </Box>
                    {isLoggedIn && (
                        <>
                            <Search>
                                <StyledInputBase placeholder="Search…" inputProps={{ 'aria-label': 'search' }} />
                            </Search>
                            <Box sx={{ flexGrow: 0, m: 1 }}>
                                <Tooltip title="Open settings">
                                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                        <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                                    </IconButton>
                                </Tooltip>
                                <Menu id="menu-appbar" anchorEl={anchorElUser} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} keepMounted transformOrigin={{ vertical: 'top', horizontal: 'right' }} open={Boolean(anchorElUser)} onClose={handleCloseUserMenu}>
                                    {settings.map((setting) => (
                                        <MenuItem key={setting} onClick={() => handleMenuClick(setting)}><Typography textAlign="center">{setting}</Typography></MenuItem>
                                    ))}
                                </Menu>
                            </Box>
                            <div>
                                <Button variant="contained" onClick={handleChangeCompanyAdd}>Add New</Button>
                                <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                                    <Box sx={style}>
                                        <Typography id="modal-modal-title" variant="h6" component="h2">
                                            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                                                <label>Video Title:</label>
                                                <TextField margin="normal" required fullWidth id="title" name="title" autoFocus onChange={(e) => setTitle(e.target.value)} />
                                                <label>Select Video:</label>
                                                <TextField margin="normal" required fullWidth id="video" name="video" autoFocus type="file" onChange={(e) => setVideo(e.target.files[0])} />
                                                <label>Select Cover Image:</label>
                                                <TextField autoFocus margin="normal" required fullWidth name="coverImage" type="file" id="coverImage" onChange={(e) => setCover(e.target.files[0])} />
                                                <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>Upload</Button>
                                            </Box>
                                        </Typography>
                                    </Box>
                                </Modal>
                            </div>
                        </>
                    )}
                    {!isLoggedIn && (<>
                        {renderHeaderButtonLogin()}
                    </>)}
                </Toolbar>
            </AppBar>
        </Box>
    );
}
