
import { jwtDecode } from 'jwt-decode';

export default function checkTokenExpiry() {
    const token = localStorage.getItem('token');
    if (token) {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000; // Convertendo para segundos
        const expiryTime = decodedToken.exp;
        const timeRemaining = expiryTime - currentTime;
        const tenMinutesInSec = 1 * 60; // 10 minutos em segundos
        console.log('expiryTime' + expiryTime);
        if (timeRemaining > tenMinutesInSec) {
            return true; // Inicia a renovação do token
        }
    }
    return false;
}