import * as React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import GuestLayout from './GuestLayout';
import SignIn from './Auth/SignIn';
import SignUp from './Auth/SignUp';

import CompanyList from './Empresa/EmpresaList';
import AddCompany from './Empresa/EmpresaAdd';
import ForgotPassword from './Auth/ForgotPassword';
import RecoveryPassword from './Auth/RecoveryPassword';
import Home from './Home/Home';
import NotFound from './NotFound/NotFound';
import PagamentoMercadoPago from './pagamento/pagamentoMercadoPago';
import PagamentoList from './pagamento/PagamentoList';

export const routes = [
    { path: '', component: SignIn, name: 'Sign In', showInMenu: false },
    { path: 'signup', component: SignUp, name: 'Sign Up', showInMenu: false },
    { path: 'add-company', component: AddCompany, name: 'Add Company', showInMenu: false },
    { path: 'company', component: CompanyList, name: 'Company List', showInMenu: false },
    { path: 'ForgotPassword', component: ForgotPassword, name: 'Forgot Password', showInMenu: false },
    { path: 'Home', component: Home, name: 'Home', showInMenu: true },
    { path: 'pagamento', component: PagamentoMercadoPago, name: 'Pagamento Mercado Pago', showInMenu: true },
    { path: 'relatorio-pagamento', component: PagamentoList, name: 'tansactions', showInMenu: true },
    { path: 'RecoveryPassword/:id', component: RecoveryPassword, name: 'Recovery Password', showInMenu: false }
];

export default function Index(props) {
    const { isLoggedIn, setLoggedIn } = props;


    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<GuestLayout isLoggedIn={isLoggedIn} setLoggedIn={setLoggedIn} />}>
                        {routes.map((route, index) => (
                            <Route key={index} path={route.path} element={<route.component setIsLoggedIn={setLoggedIn} isLoggedIn={isLoggedIn} />} />
                        ))}
                        <Route path="/404" element={<NotFound />} />
                        <Route path='*' element={<Navigate to='/404' />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </div>
    );

    /* <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<GuestLayout isLoggedIn={isLoggedIn} setLoggedIn={setLoggedIn} />}>
                            {routes.map((route, index) => (
                                <Route key={index} path={route.path} element={<route.component setIsLoggedIn={setLoggedIn} isLoggedIn={isLoggedIn} />} />
                            ))}
                        </Route>
                    </Routes>
                </BrowserRouter> */


    /* <div>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<GuestLayout isLoggedIn={isLoggedIn} setLoggedIn={setLoggedIn} />}>
                            <Route path="" element={<SignIn setIsLoggedIn={setLoggedIn} isLoggedIn={isLoggedIn} />} />
                            <Route path="signup" element={<SignUp setIsLoggedIn={setLoggedIn} />} />
                            <Route path="video" element={<VideoList setIsLoggedIn={setLoggedIn} />} />
                            <Route path="video/:id" element={<Video isLoggedIn={isLoggedIn} setIsLoggedIn={setLoggedIn} />} />
                            <Route path="add-company" element={<AddCompany isLoggedIn={isLoggedIn} setIsLoggedIn={setLoggedIn} />} />
                            <Route path="company" element={<CompanyList isLoggedIn={isLoggedIn} setIsLoggedIn={setLoggedIn} />} />
                            <Route path="ForgotPassword" element={<ForgotPassword isLoggedIn={isLoggedIn} setIsLoggedIn={setLoggedIn} />} />
                            <Route path="Home" element={<Home isLoggedIn={isLoggedIn} setIsLoggedIn={setLoggedIn} />} />
                        </Route>
                    </Routes>
                </BrowserRouter>
            </div> */

    // export default function Index(props) {
    //     const { isLoggedIn, setLoggedIn } = props
    //     return (
    //         <div>
    //             <Header isLoggedIn={isLoggedIn} />
    //             <BrowserRouter>
    //                 {isLoggedIn ?
    //                     <Routes>
    //                         <Route path="/video" element={<VideoList setLoggedIn={setLoggedIn} />}>
    //                         </Route>
    //                         <Route path="/video/:id" element={<Video setLoggedIn={setLoggedIn} />}>
    //                         </Route>
    //                     </Routes>
    //                     :
    //                     <Routes>
    //                         <Route path="/" element={<SignIn setIsLoggedIn={setLoggedIn} isLoggedIn={isLoggedIn} />}>
    //                         </Route>
    //                         <Route path="/signup" element={<SignUp setIsLoggedIn={setLoggedIn} />}>
    //                         </Route>
    //                     </Routes>
    //                 }
    //             </BrowserRouter>
    //         </div>

    //     )
}
