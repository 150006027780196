import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/material';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios';
import { url } from '../utils/constants';

export default function PagamentoList({ setIsLoggedIn }) {
    const [transactions, setTransactions] = React.useState([])
    const [tamanhoTransactions, setTamanhoTransactions] = React.useState([])

    const [loading, setLoading] = React.useState(true);




    const navigate = useNavigate();
    React.useEffect(() => {
        async function fetchData() {
            try {
                const token = await localStorage.getItem('token');
                //console.log('teste> ' + token);
                const { data } = await axios.get(url + '/api/v1/pagamento', {
                    headers: ({
                        Authorization: 'Bearer ' + token
                    })
                });
                setTransactions(data.transaction);
                console.log(data);

                setIsLoggedIn(true);
            } catch {
                setIsLoggedIn(false);
                navigate('/');
            } finally {
                setLoading(false);
                console.log(transactions);
                setTamanhoTransactions(transactions.length);
            }

        }
        fetchData();
    }, [navigate, setIsLoggedIn]);


    const handleCardClick = (id) => {
        navigate(`/ `); // Navigate to the dynamic URL
    };


    return (
        <Container>
            <Grid container spacing={2} marginTop={2}>
                {loading ? (
                    <Typography component="h2" variant="h5">Carregando... </Typography>
                ) : (
                    <>
                        {transactions.length > 0 ? (
                            transactions.map((transaction) => (
                                <Grid item xs={12} md={4} key={transaction._id}>
                                    <CardActionArea component="a" href="#" onClick={() => { handleCardClick(transaction._id) }}>
                                        <Card sx={{ display: 'flex' }}>
                                            <CardContent sx={{ flex: 1 }}>
                                                <Typography component="h2" variant="h5">
                                                    <Link to={`/transaction/${transaction._id}`} style={{ textDecoration: "none", color: "black" }}>{transaction.status}</Link>
                                                </Typography>
                                                <Typography variant="subtitle1" color="text.secondary">
                                                    {transaction.amount}
                                                </Typography>
                                                <Typography variant="subtitle1" color="text.secondary">
                                                    {transaction.wppNumber}
                                                </Typography>
                                                <Typography variant="subtitle1" color="text.secondary">
                                                    {transaction.date}
                                                </Typography>
                                                <Typography variant="subtitle1" color="text.secondary">
                                                    {transaction.id}
                                                </Typography>

                                            </CardContent>
                                        </Card>
                                    </CardActionArea>
                                </Grid>
                            ))
                        ) : (
                            <Typography component="h2" variant="h5">Nenhum pagamento encontrado</Typography>
                        )}
                    </>
                )}
            </Grid>
        </Container>
    );

}