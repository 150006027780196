import React, { useState } from 'react';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { Box, Container, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { url } from '../utils/constants';
import { useNavigate } from 'react-router-dom';

const masks = {
    '+55': { name: 'Brasil', mask: '(xx) xxxxx-xxxx' },
    '+54': { name: 'Argentina', mask: '(x) xxxx xx-xxxx' },
    '+595': { name: 'Paraguay', mask: '(xxx) xxx-xxx' },
    '+1': { name: 'EUA', mask: '(xxx) xxx-xxxx' }
    // Adicione outras máscaras conforme necessário
};


const PagamentoMercadoPago = ({ setIsLoggedIn }) => {
    const navigate = useNavigate();
    const [imgqrcode, setImgqrcode] = useState(''); // [1]
    const [phone, setPhone] = useState("");
    const [ddi, setDdi] = useState("+55");
    const [error, setError] = useState("");
    const [inputValue, setInputValue] = useState('');
    const [descricao, setDescricao] = useState('');
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        async function fetchData() {
            try {
                const token = await localStorage.getItem('token');
                //console.log('teste> ' + token);
                if (token) {
                    const { data } = await axios.get(url + '/api/v1/user/verifyToken', {
                        headers: ({
                            Authorization: 'Bearer ' + token
                        })
                    });

                    setIsLoggedIn(true);

                }
            } catch {
                setIsLoggedIn(false);

            } finally {
                setLoading(false);
            }

        }
        fetchData();
    }, [navigate, setIsLoggedIn]);

    const handleInputChange = (event) => {
        const value = event.target.value;
        let formattedValue = '';

        // Remover caracteres não numéricos
        const numericValue = value.replace(/\D/g, '');

        // Obter os centavos (últimos dois dígitos)
        const cents = numericValue.slice(-2);

        // Obter os reais (todos os dígitos exceto os últimos dois)
        const dollars = numericValue.slice(0, -2);

        // Formatar os reais com separadores de milhares
        for (let i = 0; i < dollars.length; i++) {
            formattedValue += dollars[i];
            if ((dollars.length - i - 1) % 3 === 0 && i !== dollars.length - 1) {
                formattedValue += '.';
            }
        }

        // Adicionar os centavos
        formattedValue += ',' + cents;

        setInputValue(formattedValue);
    };

    const handleDescricaoChange = (event) => {
        const { value } = event.target;
        setDescricao(value);
    }

    const normalizeInput = (value, ddi) => {
        if (!value) return value;
        const currentValue = value.replace(/[^\d]/g, '');

        // Obter a máscara correspondente ao DDI selecionado
        const mask = masks[ddi].mask;
        if (!mask) {
            // Se não houver uma máscara correspondente, retornar o valor atual sem formatação
            return currentValue;
        }

        let formattedValue = '';
        let maskIndex = 0;
        for (let i = 0; i < mask.length && maskIndex < currentValue.length; i++) {
            if (mask[i] === 'x') {
                // Se o caractere na máscara for 'x', adicionar o próximo dígito do valor atual
                formattedValue += currentValue[maskIndex];
                maskIndex++;
            } else {
                // Se o caractere na máscara não for 'x', adicionar o próprio caractere
                formattedValue += mask[i];
            }
        }
        return formattedValue;
    };

    const handleChange = (event) => {
        const { value } = event.target;
        //setPhone(value);
        setPhone(normalizeInput(value, ddi));

    };

    const handleDdiChange = (event) => {
        const { value } = event.target;
        setDdi(value);
        //handleReset();
    };

    const handleSubmit = async () => {
        const token = localStorage.getItem('token');

        const headers = {
            Authorization: `Bearer ${token}`
        };

        await axios.post(url + '/api/v1/pagamento/', { "numero": (ddi + phone).replace(/[()\s+-]/g, ""), "valor": inputValue, "descricao": descricao }, { headers })
            .then((response) => {
                console.log(response.data);
                // Lógica para lidar com a resposta da requisição
                setImgqrcode(response.data.pagamentoQrCode); // [2]

            })
            .catch((error) => {
                console.log(error);
                // Lógica para lidar com erros na requisição
            });
        handleReset();
    };

    const handleReset = () => {
        setPhone("");

        setError("");
    };

    return (
        <Container maxWidth="sm">
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <Box sx={{ display: 'flex' }}>
                    <TextField
                        label="Valor a ser pago"
                        value={inputValue}
                        onChange={handleInputChange}
                        placeholder="0,00"  // [4]
                    />
                </Box>
                <Box sx={{ display: 'flex' }}>
                    <TextField
                        label="Descrição do pagamento"
                        value={descricao}
                        onChange={handleDescricaoChange}
                        placeholder="Ex: João"  // [4]
                    />
                </Box>
                <Box sx={{ display: 'flex' }}>
                    <FormControl sx={{ mr: 2, mt: 3 }}>
                        <InputLabel id="ddi-label">DDI</InputLabel>
                        <Select
                            labelId="ddi-label"
                            id="ddi"
                            name="ddi"
                            value={ddi}
                            onChange={handleDdiChange}
                        >
                            {Object.keys(masks).map((countryCode) => (
                                <MenuItem key={countryCode} value={countryCode}>
                                    {`${countryCode} - ${masks[countryCode].name}`}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Box sx={{ flexGrow: 1 }}>
                        <Typography>Seu número de telefone:</Typography>
                        <TextField
                            type="tel"
                            placeholder={masks[ddi].mask}
                            value={phone}
                            fullWidth
                            name="phoneNumber"
                            onChange={handleChange}
                        />
                        {error && <p style={{ color: 'red' }}>{error}</p>}
                    </Box>
                </Box>
                <Button type="submit" variant="contained">Enviar</Button>
                {imgqrcode && <><img src={`data:image/bmp;base64,${imgqrcode}`} alt="QR Code" /> {/* [3] */}</>}
            </Box>
        </Container>
    );
};

export default PagamentoMercadoPago;