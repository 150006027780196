import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, TextField, Typography, Box, Button } from '@mui/material';
import axios from 'axios';
import { url } from '../utils/constants';

const RecoveryPassword = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [message, setMessage] = useState('');
    const [password, setPassword] = useState('');
    const [passwordVerify, setPasswordVerify] = useState('');
    const [senhaValida, setSenhaValida] = useState(false);
    const [qtdCaracteresValida, setQtdCaracteresValida] = useState(false);
    const [temMaiuscula, setTemMaiuscula] = useState(false);
    const [temMinuscula, setTemMinuscula] = useState(false);
    const [temNumero, setTemNumero] = useState(false);
    const [temCaracterEspecial, setTemCaracterEspecial] = useState(false);
    const patternUPPER = new RegExp("^(?=.*[A-Z])");
    const patternLOWER = new RegExp("^(?=.*[a-z])");
    const patternNUMBER = new RegExp("^(?=.*\\d)");
    const patternESPECIAL = new RegExp("^(?=.*[-+_!@#$%^&*.,?]).+$");

    const handleIsValidPassword = (password) => {
        setQtdCaracteresValida(false);
        setTemMaiuscula(false)
        setTemMinuscula(false)
        setTemNumero(false)
        setTemCaracterEspecial(false)
        setSenhaValida(false)
        if (password.length >= 8 && password.length <= 16) {
            setQtdCaracteresValida(true);
            if (patternUPPER.test(password)) {
                setTemMaiuscula(true)
                if (patternLOWER.test(password)) {
                    setTemMinuscula(true)
                    if (patternNUMBER.test(password)) {
                        setTemNumero(true)
                        if (patternESPECIAL.test(password)) {
                            setTemCaracterEspecial(true)
                            setSenhaValida(true)
                            return true;
                        }

                    }

                }

            }

        }
        return false;
    }

    const handlePasswordEquals = () => {
        if (password == passwordVerify)
            return true;
        return false;
    }

    const handleSubmit = async () => {
        try {

            if (senhaValida && handlePasswordEquals()) {
                await axios.post(url + '/api/v1/user/recovery-password', {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + id
                    },
                    password
                });

            } else {
                console.log(message)
                setMessage("não atende aos criterios")
            }
        } catch (error) {
            throw error;
        }
    }

    const handleOnChangePassword = async (event) => {
        const { value } = event.target;
        await setPassword(value)
        console.log(handleIsValidPassword(value))
    }

    const handleOnChangePasswordVerify = async (event) => {
        const { value } = event.target;
        await setPasswordVerify(value)

    }

    return (
        <Container maxWidth="sm">
            <Box marginTop={2} alignContent={'center'} sx={{ my: 4 }} p={4}>
                <Typography variant='h6' component='h1'>Redifina sua senha</Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <Typography>Sua senha:</Typography>
                    <TextField type="password" value={password} fullWidth onChange={handleOnChangePassword} />
                    <Typography>Confirme a senha:</Typography>
                    <TextField type="password" value={passwordVerify} fullWidth onChange={handleOnChangePasswordVerify} />
                    <Typography style={{ color: senhaValida ? 'green' : 'red' }} id='todosCriterios'>
                        A senha deve ter pelo menos:
                    </Typography>
                    <Typography style={{ color: qtdCaracteresValida ? 'green' : 'red' }} id='qtdCaracteres'>
                        * 8 a 16 caracteres
                    </Typography>
                    <Typography style={{ color: temMaiuscula ? 'green' : 'red' }} id='temMaiuscula'>
                        * Pelo menos 1 maiúscula
                    </Typography>
                    <Typography style={{ color: temMinuscula ? 'green' : 'red' }} id='temMinuscula'>
                        * Pelo menos 1 minúscula
                    </Typography>
                    <Typography style={{ color: temNumero ? 'green' : 'red' }} id='temNumero'>
                        * Pelo menos 1 número
                    </Typography>
                    <Typography style={{ color: temCaracterEspecial ? 'green' : 'red' }} id='temCaracterEspecial'>
                        * Pelo menos 1 caractere especial
                    </Typography>
                    {message && <p>{message}</p>}
                    <Box mt={2}>
                        <Button type="submit" variant="contained" color="primary">Enviar</Button>
                    </Box>

                </Box>
            </Box>
        </Container>
    );
};

export default RecoveryPassword;

// <Typography id='todosCriterios'>A senha deve ter pelomenos:</Typography>
// <Typography id='qtdCaracteres'>*8 a 16 carateres</Typography>
// <Typography id='temMaiuscula'>*1 maiuscula</Typography>
// <Typography id='temMinuscula'>*1 minuscula</Typography>
// <Typography id='temNumero'>*1 numero</Typography>
// <Typography id='temCaracterEspecial'>*1 caracter especial:</Typography>