import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { InputLabel, Container, TextField, Typography, Box, Button, RadioGroup, FormControlLabel, Radio, FormControl, FormLabel, Select, MenuItem } from '@mui/material';
import axios from 'axios';
import { url } from '../utils/constants';

const masks = {
    '+55': { name: 'Brasil', mask: '(xx) xxxxx-xxxx' },
    '+595': { name: 'Paraguay', mask: '(xxx) xxx-xxx' },
    '+1': { name: 'EUA', mask: '(xxx) xxx-xxxx' }
    // Adicione outras máscaras conforme necessário
};

const validateInput = (value, ddi) => {
    if (!value) return "Required!";
    if (value.length !== masks[ddi].mask.length) return `Invalid phone format. ${masks[ddi].mask}`;
    return "";
};


const ForgotPassword = () => {
    const navigate = useNavigate();
    const [phone, setPhone] = useState("");
    const [error, setError] = useState("");
    const [deliveryMethod, setDeliveryMethod] = useState("email");
    const [ddi, setDdi] = useState("+55");
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const formattedPhoneNumber = useRef('');

    useEffect(() => {
        formattedPhoneNumber.current = (ddi + phone).replace(/[()\s+-]/g, "");
    }, [ddi, phone]);

    const normalizeInput = (value, ddi) => {
        if (!value) return value;
        const currentValue = value.replace(/[^\d]/g, '');

        // Obter a máscara correspondente ao DDI selecionado
        const mask = masks[ddi].mask;
        if (!mask) {
            // Se não houver uma máscara correspondente, retornar o valor atual sem formatação
            return currentValue;
        }

        let formattedValue = '';
        let maskIndex = 0;
        for (let i = 0; i < mask.length && maskIndex < currentValue.length; i++) {
            if (mask[i] === 'x') {
                // Se o caractere na máscara for 'x', adicionar o próximo dígito do valor atual
                formattedValue += currentValue[maskIndex];
                maskIndex++;
            } else {
                // Se o caractere na máscara não for 'x', adicionar o próprio caractere
                formattedValue += mask[i];
            }
        }
        return formattedValue;
    };

    const handleChange = (event) => {
        const { value } = event.target;
        //setPhone(value);
        setPhone(normalizeInput(value, ddi));

    };

    const handleRadioChange = (event) => {
        const { value } = event.target;
        setDeliveryMethod(value);
        handleReset();
    };

    const handleDdiChange = (event) => {
        const { value } = event.target;
        setDdi(value);
        handleReset();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let error = '';

        if (deliveryMethod === 'whatsapp') {
            error = validateInput(phone, ddi);
        } else if (deliveryMethod === 'email') {
            error += 'email ' + isEmailValid(email);
        }
        setError(error);

        if (!error) {
            try {
                await axios.post(url + '/api/v1/user/forgot-password', { phoneNumber: formattedPhoneNumber.current, email, deliveryMethod });
                if (deliveryMethod == 'whatsapp')
                    setMessage('Uma mensagem de redefinição de senha foi enviado para o seu whatsapp.');
                else
                    setMessage('Um e-mail de redefinição de senha foi enviado para o seu endereço de e-mail.');
            } catch (error) {
                if (deliveryMethod == 'whatsapp')
                    setMessage('Ocorreu um erro ao enviar mensagem de redefinição de senha. Verifique o whatsapp. ' + error);
                else
                    setMessage('Ocorreu um erro ao enviar o e-mail de redefinição de senha. Verifique o endereço de e-mail. ' + error);
            }
        }
    };

    const handleReset = () => {
        setPhone("");
        setEmail('');
        setError("");
    };

    const isEmailValid = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleEmailChange = (event) => {
        const { value } = event.target;
        if (!isEmailValid(value)) {
            console.log('E-mail inválido');
        }
        setEmail(value);
    };

    const handleGoHome = () => {
        navigate('/')
    }

    return (
        <Container maxWidth="sm">
            <Box marginTop={2} alignContent={'center'} sx={{ my: 4 }} p={4}>
                <Typography variant='h6' component='h1'>Esqueceu sua senha?</Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Como deseja solicitar a troca</FormLabel>
                        <RadioGroup row aria-label="gender" name="row-radio-buttons-group" value={deliveryMethod} onChange={handleRadioChange}>
                            <FormControlLabel value="email" control={<Radio />} label="E-mail" />
                            <FormControlLabel value="whatsapp" control={<Radio />} label="WhatsApp" />
                        </RadioGroup>
                    </FormControl>
                    {deliveryMethod === "whatsapp" && <>
                        <Box sx={{ display: 'flex' }}>
                            <FormControl sx={{ mr: 2, mt: 3 }}>
                                <InputLabel id="ddi-label">DDI</InputLabel>
                                <Select
                                    labelId="ddi-label"
                                    id="ddi"
                                    name="ddi"
                                    value={ddi}
                                    onChange={handleDdiChange}
                                >
                                    {Object.keys(masks).map((countryCode) => (
                                        <MenuItem key={countryCode} value={countryCode}>
                                            {`${countryCode} - ${masks[countryCode].name}`}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <Box sx={{ flexGrow: 1 }}>
                                <Typography>Seu número de telefone:</Typography>
                                <TextField
                                    type="tel"
                                    placeholder={masks[ddi].mask}
                                    value={phone}
                                    fullWidth
                                    name="phoneNumber"
                                    onChange={handleChange}
                                />
                                {error && <p style={{ color: 'red' }}>{error}</p>}
                            </Box>
                        </Box>
                    </>}
                    {deliveryMethod === 'email' && (<>
                        <Typography>Seu e-mail:</Typography>
                        <TextField type="email" placeholder="exemplo@exemplo.com" value={email} fullWidth onChange={handleEmailChange} />
                    </>
                    )}
                    <Box mt={2}>
                        <Button type="submit" variant="contained" color="primary">Enviar</Button>
                        <Button type="button" variant="contained" color="secondary" onClick={handleReset} sx={{ ml: 1 }}>Limpar</Button>
                    </Box>
                    <Button type="button" variant="contained" color="secondary" onClick={handleGoHome} sx={{ ml: 1 }}>Voltar</Button>
                    {message && <p>{message}</p>}
                </Box>
            </Box>
        </Container>
    );
};

export default ForgotPassword;
