import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/material';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios';
import { url } from '../utils/constants';

export default function EmpresaList({ setIsLoggedIn }) {
    const [empresas, setEmpresas] = React.useState([])
    const [loading, setLoading] = React.useState(true);




    const navigate = useNavigate();
    React.useEffect(() => {
        async function fetchData() {
            try {
                const token = await localStorage.getItem('token');
                //console.log('teste> ' + token);
                const { data } = await axios.get(url + '/api/v1/empresa', {
                    headers: ({
                        Authorization: 'Bearer ' + token
                    })
                });
                setEmpresas(data);
                setIsLoggedIn(true);
            } catch {
                setIsLoggedIn(false);
                navigate('/');
            } finally {
                setLoading(false);
            }

        }
        fetchData();
    }, [navigate, setIsLoggedIn]);


    const handleCardClick = (id) => {
        navigate(`/ `); // Navigate to the dynamic URL
    };


    return (
        <Container>
            <Grid container spacing={2} marginTop={2}>
                {loading ? (<Typography component="h2" variant="h5">Carregando... </Typography>) :
                    (
                        empresas.map((empresa) => {
                            return <Grid item xs={12} md={4} key={empresa._id}>
                                <CardActionArea component="a" href="#"
                                    onClick={() => { handleCardClick(empresa._id) }}>
                                    <Card sx={{ display: 'flex' }}>
                                        <CardContent sx={{ flex: 1 }}>
                                            <Typography component="h2" variant="h5">
                                                <Link to={`/empresa/${empresa._id}`} style={{ textDecoration: "none", color: "black" }}>{empresa.razonSocial}</Link>
                                            </Typography>
                                            <Typography variant="subtitle1" color="text.secondary">
                                                {empresa.nombreFantasia}
                                            </Typography>
                                        </CardContent>
                                        <CardMedia
                                            component="img"
                                            sx={{ width: 160, display: { xs: 'none', sm: 'block' } }}
                                            image={url + '/${empresa.logo}'}
                                            alt="alt"
                                        />
                                    </Card>
                                </CardActionArea>
                            </Grid>
                        })
                    )}
            </Grid>
        </Container >
    );
}