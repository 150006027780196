import * as React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { url } from '../utils/constants';

export default function Home({ setIsLoggedIn }) {
    const [errorMessage, setErrorMessage] = React.useState('');
    const [loading, setLoading] = React.useState(true);

    const navigate = useNavigate();

    // Verificar se o usuário já está logado ao montar o componente
    React.useEffect(() => {
        async function fetchData() {
            try {
                const token = await localStorage.getItem('token');
                if (token) {
                    const { data } = await axios.get(url + '/api/v1/user/verifyToken', {
                        headers: ({
                            Authorization: 'Bearer ' + token
                        })
                    });

                    setIsLoggedIn(true);
                }
            } catch {
                setIsLoggedIn(false);
                navigate('/');
            } finally {
                setLoading(false);
            }

        }
        fetchData();
    }, [navigate, setIsLoggedIn]);

    return (
        <Container>
            <Typography content='h2' variant='h5'>Home</Typography>
            {loading && <Typography>Carregando...</Typography>}
            {!loading && <Typography>Seja bem-vindo!</Typography>}
        </Container>
    );
}
