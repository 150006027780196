import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { url } from '../utils/constants';
const theme = createTheme();

export default function SignIn({ setIsLoggedIn }) {
  const [errorMessage, setErrorMessage] = React.useState('');
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);

  // Verificar se o usuário já está logado ao montar o componente
  React.useEffect(() => {
    async function fetchData() {
      try {
        const token = await localStorage.getItem('token');
        //console.log('teste> ' + token);
        if (token) {
          const { data } = await axios.get(url + '/api/v1/user/verifyToken', {
            headers: ({
              Authorization: 'Bearer ' + token
            })
          });

          setIsLoggedIn(true);
          navigate('/home')
        }
      } catch {
        setIsLoggedIn(false);

      } finally {
        setLoading(false);
      }

    }
    fetchData();
  }, [navigate, setIsLoggedIn]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const form = {
      email: formData.get('email'),
      password: formData.get('password')
    };

    try {
      let response = await axios.post(url + '/api/v1/user/signin', form);
      console.log("responselogin: ", response.data)
      if (response && response.data) {
        const { data } = response;
        // localStorage.setItem('token', data.token);
        // setIsLoggedIn(true);
        // navigate('/video');
        if (data) {

          localStorage.setItem('token', data.token);
          setIsLoggedIn(true);
          navigate('/Home');
        } else {
          // Tratar o caso em que não há dados retornados pela requisição
          console.error('Dados não encontrados na resposta da requisição.');
        }
      } else {
        console.error('Resposta invalida.');
      }

    } catch (error) {
      console.log(error.response.data)
      setErrorMessage(error.response.data.message);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}></Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            {errorMessage && (
              <Typography component="p" variant="p" color="red">
                {errorMessage}
              </Typography>
            )}
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/ForgotPassword" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="SignUp" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
