import * as React from 'react';
import { useParams } from 'react-router-dom';
import checkTokenExpiry from '../Auth/VerifyAuth';

import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import { Container, TextField, Typography, Box, Button, Grid } from '@mui/material';
import { url } from '../utils/constants';

const style = {
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};



function AddCompany({ setIsLoggedIn }) {
    const { id } = useParams();
    const navigate = useNavigate();
    //const [empresaInfo, setEmpresaInfo] = React.useState([]);
    const [empresaId] = React.useState(id);

    const [formData, setFormData] = React.useState({
        ruc: '',
        razonSocial: '',
        nombreFantasia: '',
        timbradoNumero: '',
        timbradoFecha: '',
        cover: '',
        tipoContribuyente: '',
        tipoRegimen: '',
        actividadesEconomicas: [
            {
                codigo: '',
                descripcion: '',
            }
        ],
        establecimientos: [
            {
                codigo: '',
                direccion: '',
                numeroCasa: '',
                complementoDireccion1: '',
                complementoDireccion2: '',
                departamento: '',
                departamentoDescripcion: '',
                distrito: '',
                distritoDescripcion: '',
                ciudad: '',
                ciudadDescripcion: '',
                telefono: '',
                email: '',
                denominacion: '',
                logo: '',
            }
        ],

    });

    React.useEffect(() => {
        document.title = "Adicionar nueva Empresa";
        async function fetchData() {
            try {
                if (!checkTokenExpiry()) {
                    navigate('/');
                    return;
                }
                const token = localStorage.getItem('token');
                await axios.get(url + '/api/v1/empresa', {
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                });
                //setEmpresaInfo(data);
                setIsLoggedIn(true);
            } catch (error) {
                console.error('Error fetching video details:', error);
                navigate('/');
            }
        }
        fetchData();
    }, [empresaId, navigate, setIsLoggedIn]);

    const handleSubmit = (e) => {
        e.preventDefault();
        // Lógica para enviar os detalhes do vídeo para o servidor
        // e redirecionar para a página de lista de vídeos após o envio bem-sucedido
        // Exemplo fictício:
        console.log('Ruc:', formData.ruc);
        console.log('Razon social:', formData.razon_social);
        console.log('Nombre fantasia:', formData.nombre_fantasia);
        console.log('timbrado:', formData.timbrado);
        // Redirecionamento
        //history.push('/Empresa');
    };

    return (
        <Container justifyContent>
            <Typography sx={{ textAlign: 'center', fontSize: '24px', fontFamily: 'Arial, sans-serif' }}>Adicionar nueva Empresa</Typography>
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <Grid container spacing={2}>
                            {/* Campos para a empresa */}
                            <Grid item xs={12} md={4} lg={4}>
                                <label>RUC:</label>
                                <TextField
                                    required
                                    fullWidth
                                    autoFocus
                                    type="text"
                                    value={formData.ruc}
                                    onChange={(e) => setFormData({ ...formData, ruc: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={12} md={8} lg={8}>
                                <label>Razon social:</label>
                                <TextField
                                    required
                                    fullWidth
                                    autoFocus
                                    type="text"
                                    value={formData.razon_social}
                                    onChange={(e) => setFormData({ ...formData, razon_social: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <label>Nombre fantasia:</label>
                                <TextField
                                    required
                                    fullWidth
                                    autoFocus
                                    type="text"
                                    value={formData.nombre_fantasia}
                                    onChange={(e) => setFormData({ ...formData, nombre_fantasia: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <label>Timbrado:</label>
                                <TextField
                                    required
                                    fullWidth
                                    autoFocus
                                    type="text"
                                    value={formData.timbradoNumero}
                                    onChange={(e) => setFormData({ ...formData, timbradoNumero: e.target.value })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <label>Fecha timbrado:</label>
                                <TextField
                                    required
                                    fullWidth
                                    autoFocus
                                    type="date"
                                    value={formData.timbradoFecha}
                                    onChange={(e) => setFormData({ ...formData, timbradoFecha: e.target.value })}
                                />
                            </Grid>
                            {/* Campos para atividade econômica */}
                            <Grid item xs={12} md={12} lg={12}>
                                <label>Actividad/es economica/s</label>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <label>Codigo:</label>
                                <TextField
                                    required
                                    autoFocus
                                    margin="normal"
                                    fullWidth
                                    type="text"
                                    onChange={(e) => setFormData({ ...formData, actividadesEconomicas: { ...formData.actividadesEconomicas, codigo: e.target.value } })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <label>Descripción:</label>
                                <TextField
                                    required
                                    autoFocus
                                    margin="normal"
                                    fullWidth
                                    type="text"
                                    onChange={(e) => setFormData({ ...formData, actividadesEconomicas: { ...formData.actividadesEconomicas, descripcion: e.target.value } })}
                                />
                            </Grid>
                            {/* Campos para a matriz */}
                            <Grid item xs={12} md={12} lg={12} alignContent={'center'} justifyContent={'center'}>
                                <label>Datos de la Matriz</label>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <label>Codigo:</label>
                                <TextField
                                    required
                                    autoFocus
                                    margin="normal"
                                    fullWidth
                                    type="text"
                                    onChange={(e) => setFormData({ ...formData, matriz: { ...formData.matriz, codigo: e.target.value } })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <label>Dirección:</label>
                                <TextField
                                    required
                                    autoFocus
                                    margin="normal"
                                    fullWidth
                                    type="text"
                                    onChange={(e) => setFormData({ ...formData, matriz: { ...formData.matriz, direccion: e.target.value } })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <label>Número:</label>
                                <TextField
                                    required
                                    autoFocus
                                    margin="normal"
                                    fullWidth
                                    type="text"
                                    onChange={(e) => setFormData({ ...formData, matriz: { ...formData.matriz, numero: e.target.value } })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <label>Complemento dirección:</label>
                                <TextField
                                    required
                                    autoFocus
                                    margin="normal"
                                    fullWidth
                                    type="text"
                                    onChange={(e) => setFormData({ ...formData, matriz: { ...formData.matriz, complementoDireccion: e.target.value } })}
                                />
                            </Grid>
                            {/* Outros campos para a matriz... */}
                            {/* Campos para contato */}
                            <Grid item xs={12} md={12} lg={12}>
                                <label>Informações de contato</label>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <label>Telefone:</label>
                                <TextField
                                    required
                                    autoFocus
                                    margin="normal"
                                    fullWidth
                                    type="text"
                                    onChange={(e) => setFormData({ ...formData, contato: { ...formData.contato, telefone: e.target.value } })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <label>E-mail:</label>
                                <TextField
                                    required
                                    autoFocus
                                    margin="normal"
                                    fullWidth
                                    type="text"
                                    onChange={(e) => setFormData({ ...formData, contato: { ...formData.contato, email: e.target.value } })}
                                />
                            </Grid>
                            {/* Outros campos para contato... */}
                            {/* Campo para logo */}
                            <Grid item xs={12} md={12} lg={12}>
                                <label>Logo:</label>
                                <TextField
                                    required
                                    autoFocus
                                    margin="normal"
                                    fullWidth
                                    type="file"
                                    onChange={(e) => setFormData({ ...formData, logo: e.target.files[0] })}
                                />
                            </Grid>
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Adicionar Empresa
                        </Button>
                    </Box>
                </Typography>
            </Box>
        </Container>
    );

}

export default AddCompany;
